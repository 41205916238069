import { useNavigate, useParams } from 'react-router-dom';
import bg from './assets/WeChat Image_20230625170326.jpg';
import inscribeStyles from './Inscribe.module.scss';
import featuredImage from './assets/Untitled-1.gif';
import { useEffect, useState } from 'react';
import { fetchOrder, getAppMetadata, getInscribeInfo, InscribeInfo, Order } from './API';
import Swal from 'sweetalert2';
import styles from './OrderDetails.module.scss';
import BigNumber from 'bignumber.js';
import { Tooltip } from './Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { copySuccess } from './helpers';

export function OrderDetails() {
    const {id: orderId} = useParams<{ id: string }>();
    const [order, setOrder] = useState<Order | null>(null);
    const navigate = useNavigate();
    const [price, setPrice] = useState<number>(0);

    // load metadata once
    useEffect(() => {
        getAppMetadata().then(metadata => {
            setPrice(metadata.price);
        });
    }, []);

    // load order
    useEffect(() => {
        fetchOrder(parseInt(orderId!)).then(order => {
            setOrder(order);
            console.log(order);
        }).catch(e => {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: e.message || 'Something went wrong while loading your order. Please try again later.',
                allowOutsideClick: false,
            }).then(() => navigate('/'));
        });

        const i = setInterval(() => {
            fetchOrder(parseInt(orderId!)).then(order => {
                setOrder(order);
                console.log(order.status, order);
            });
        }, 10000);
        return () => clearInterval(i);
    }, []);

    const serviceFeeSats = parseInt(process.env.REACT_APP_SERVICE_FEE_SATS!);

    function OrderStatusBadge() {
        if (!order) return <></>;

        switch (order.status) {
            case 'pending':
                return <span className="badge text-bg-warning">Pending</span>;
            case 'inscribing':
                return <span className="badge text-bg-primary">Inscribing</span>;
            case 'awaiting':
                return <span className="badge text-bg-info">Awaiting</span>;
            case 'completed':
                return <span className="badge text-bg-success">Completed</span>;
            case 'failed':
                return <span className="badge text-bg-danger">Failed</span>;
            case 'cancelled':
                return <span className="badge text-bg-danger">Cancelled</span>;
            default:
                return <span className="badge text-bg-secondary">Unknown</span>;
        }
    }

    return <div className="bg-fit" style={{backgroundImage: `url("${bg}")`}}>
        <div className="container" style={{minHeight: '100vh'}}>
            <div className={`row ${inscribeStyles.mainContent}`}>
                <div className="col-12 col-lg-6">
                    <img src={featuredImage}
                         style={{
                             maxWidth: '35rem',
                             width: '100%',
                             borderRadius: '1.5rem',
                             imageRendering: 'pixelated'
                         }}/>
                </div>
                <div className={`col-12 col-lg-6 ${inscribeStyles.mintInfo}`}>
                    <div className="row">
                        <div className="col">
                            <h1 className={inscribeStyles.mintInfoTitle}>Order #{orderId}</h1>
                        </div>
                        <div className="col-auto align-items-center d-flex">
                            <p style={{fontSize: '2.5rem'}} className="mb-0">
                                <OrderStatusBadge/>
                            </p>
                        </div>
                    </div>
                    <OrderDetails/>
                </div>
            </div>
            <div className={inscribeStyles.socialLinks}>
                <a href="https://twitter.com/OnchainHares" target="_blank" rel="noreferrer">
                    Twitter
                </a>
                <a href="https://discord.gg/WAS4kj8Zpx" target="_blank" rel="noreferrer">
                    Discord
                </a>
            </div>
        </div>
    </div>;

    function OrderDetails() {
        if (!order) return <div className="row">
            <div className="col">
                <h3>Loading... Please wait</h3>
            </div>
        </div>

        switch (order.status) {
            case 'pending':
                return <PendingOrder/>;

            case 'inscribing':
                return <InscribingOrder/>;

            case 'awaiting':
                return <AwaitingOrder/>;

            case 'completed':
                return <CompletedOrder/>;

            case 'failed':
                return <FailedOrder/>;

            case 'cancelled':
                return <CancelledOrder/>;

            default:
                return <UnknownOrder/>;
        }
    }

    function PendingOrder() {
        const [payDeadlineText, setPayDeadlineText] = useState<string>('');
        const [softCancelled, setSoftCancelled] = useState<boolean>(false);

        useEffect(() => {
            // format time left to hh:ss
            const deadline = new Date((order!.pay_deadline - 60) * 1000);
            const i = setInterval(() => {
                const now = new Date();
                const diff = deadline.getTime() - now.getTime();
                const minutes = Math.floor(diff / 60000);
                const seconds = Math.floor((diff % 60000) / 1000);
                setPayDeadlineText(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

                if (Date.now() > ((order!.pay_deadline - 60) * 1000)) {
                    setSoftCancelled(true);
                }
            }, 1000);

            return () => clearInterval(i);
        });

        if (softCancelled) {
            return <CancelledOrder/>;
        }

        return <div className="row">
            <PriceDetails/>
            <div className="col-12">
                <div className="input-group mb-3">
                    <span className="input-group-text">Pay Address</span>
                    <input type="text" className="form-control" readOnly={true} value={order!.pay_address}/>
                    <CopyToClipboard text={order!.pay_address} onCopy={copySuccess}>
                        <button className="btn btn-outline-secondary" type="button">Copy</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="col-12">
                <div className="input-group mb-3">
                    <span className="input-group-text">Amount (BTC)</span>
                    <input type="text" className="form-control" readOnly={true}
                           value={getBitcoinFromSatoshi(order!.pay_amount)}/>
                    <CopyToClipboard text={getBitcoinFromSatoshi(order!.pay_amount)} onCopy={copySuccess}>
                        <button className="btn btn-outline-secondary" type="button">Copy</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="col-12">
                <div className="input-group mb-3">
                    <span className="input-group-text">Time Left</span>
                    <input type="text" className="form-control" readOnly={true}
                           value={payDeadlineText}/>
                </div>
            </div>
            <div className="col-12 pay-notes">
                <ol>
                    <li>
                        Please pay the funds <b>before the order expires</b> (confirmation not required). Otherwise,
                        your order will be cancelled.
                    </li>
                    <li>
                        <b>DO NOT</b> withdraw from exchange. This will result to order timeout.
                    </li>
                    <li>
                        Please send money with a <strong>slightly higher fee</strong> (above the market fee) to mitigate
                        the risk of order failures.
                    </li>
                    <li>
                        Please send <strong>no
                        less</strong> than <strong>{getBitcoinFromSatoshi(order!.pay_amount)} BTC</strong> in <strong>ONE
                        transaction</strong>. Otherwise, your order will fail.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function InscribingOrder() {
        return <div className="row">
            <PriceDetails/>
            <div className="col-12">
                <div className="input-group mb-3">
                    <span className="input-group-text">Payment TxID</span>
                    <input type="text" className="form-control" readOnly={true} value={order!.pay_txid!}/>
                    <a className="btn btn-outline-secondary" href={`https://mempool.space/tx/${order!.pay_txid!}`}
                       target="_blank">View</a>
                </div>
            </div>
            <div className="col-12 pay-notes">
                <ol>
                    <li>
                        We have received your payment. Please wait for the inscribing process to complete.
                    </li>
                    <li>
                        This usually takes <strong>5-30 minutes</strong>. Please be patient.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function AwaitingOrder() {
        const [inscribeInfo, setInscribeInfo] = useState<InscribeInfo | null>(null);

        useEffect(() => {
            getInscribeInfo(order!.id).then(setInscribeInfo)
        }, []);

        const inscribeTxInputGroups = [];
        if (inscribeInfo) {
            for (const [i, asset] of inscribeInfo.assets.entries()) {
                inscribeTxInputGroups.push(<div className="col-12" key={i}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Inscribe Tx #{i + 1}</span>
                        <input type="text" className="form-control" readOnly={true} value={asset.inscription_txid}/>
                        <a className="btn btn-outline-secondary"
                           href={`https://mempool.space/tx/${asset.inscription_txid}`}
                           target="_blank">View</a>
                    </div>
                </div>);
            }
        }


        return <div className="row">
            <PriceDetails/>
            {inscribeTxInputGroups}
            <div className="col-12 pay-notes">
                <ol>
                    <li>
                        Your inscription has been broadcasted to the blockchain. Please wait for the blockchain
                        confirmation.
                    </li>
                    <li>
                        This takes <b>10 minutes in average</b>. Please be patient.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function CompletedOrder() {
        const [inscribeInfo, setInscribeInfo] = useState<InscribeInfo | null>(null);

        useEffect(() => {
            getInscribeInfo(order!.id).then(setInscribeInfo)
        }, []);

        const inscribeTxInputGroups = [];
        if (inscribeInfo) {
            for (const [i, asset] of inscribeInfo.assets.entries()) {
                inscribeTxInputGroups.push(<div className="col-12" key={i}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Inscription ID #{i + 1}</span>
                        <input type="text" className="form-control" readOnly={true} value={asset.inscription_id!}/>
                        <a className="btn btn-outline-secondary"
                           href={`https://ordinals.com/inscription/${asset.inscription_id!}`}
                           target="_blank">View</a>
                    </div>
                </div>);
            }
        }

        return <div className="row">
            <PriceDetails/>
            {inscribeTxInputGroups}
            <div className="col-12 pay-notes">
                <ol>
                    <li>
                        Your inscription has been confirmed by the blockchain. Inscriptions should be available in your
                        wallet.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function FailedOrder() {
        return <div className="row">
            <PriceDetails/>
            <div className="col-12">
                <h3>
                    We failed to inscribe your order.<br/>
                    This is usually caused by insufficient funds being paid.
                </h3>
            </div>
            <div className="col-12 pay-notes">
                <p className="mb-1">What should I do?</p>
                <ol>
                    <li>
                        Don't worry. All funds are safe. Your payment will be refunded after the sale ends.
                    </li>
                    <li>
                        Your quota has been released. You can place a new order.
                    </li>
                    <li>
                        If you have any questions, please contact us on Twitter or Discord.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function CancelledOrder() {
        return <div className="row">
            <PriceDetails/>
            <div className="col-12">
                <h3>
                    Your order has been cancelled.<br/>We don't receive your payment in time.
                </h3>
            </div>
            <div className="col-12 pay-notes">
                <p className="mb-1">I still paid, what should I do?</p>
                <ol>
                    <li>
                        Don't worry. All funds are safe. Your payment will be refunded after the sale ends.
                    </li>
                    <li>
                        Your quota has been released. You can place a new order.
                    </li>
                    <li>
                        If you have any questions, please contact us on Twitter or Discord.
                    </li>
                </ol>
            </div>
        </div>;
    }

    function UnknownOrder() {
        return <div className="row mt-5">
            <div className="col">
                <h3>Something went wrong.</h3>
                <p>
                    If you think this is a mistake, please contact us on Twitter or Discord.
                </p>
            </div>
        </div>;
    }

    function PriceDetails() {
        let assetTotal;
        if (order!.is_freemint) {
            assetTotal = new BigNumber(0);
        } else {
            assetTotal = (new BigNumber(price)).multipliedBy(order!.asset_amount);
        }
        const serviceFeeTotal = (new BigNumber(serviceFeeSats)).multipliedBy(order!.asset_amount);
        const txFeeTotal = (new BigNumber(order!.pay_amount)).minus(assetTotal).minus(serviceFeeTotal);

        return <div className={`col-12 ${styles.priceDetails}`}>
            <div className="row">
                <div className="col">
                    <h3>Price Details</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table className="table table-hover table-borderless">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Pixel Hares</td>
                            <td>{order!.asset_amount}</td>
                            <td>{assetTotal.isEqualTo(0) ? 'Free' : <>{getBitcoinFromSatoshi(assetTotal)} BTC</>}</td>
                        </tr>
                        <tr>
                            <Tooltip text="Powered by Pixel Birds Team" placement="top">
                                <td>Service Fee (?)</td>
                            </Tooltip>
                            <td>{order!.asset_amount}</td>
                            <td>{getBitcoinFromSatoshi(serviceFeeTotal)} BTC</td>
                        </tr>
                        <tr>
                            <td>Transaction Fee</td>
                            <td>1</td>
                            <td>{getBitcoinFromSatoshi(txFeeTotal)} BTC</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={2}>Total</td>
                            <td>{getBitcoinFromSatoshi(order!.pay_amount)} BTC</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>;
    }

    function getBitcoinFromSatoshi(satoshi: BigNumber | number): string {
        const bn = new BigNumber(satoshi);
        return bn.dividedBy(1e8).toString(10);
    }
}
