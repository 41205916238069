async function apiReq(path: string, method: string = 'GET', data: any = {}) {
    const endpoint = 'https://api.onchainhares.com/api/v1';
    // const endpoint = 'https://api.bitknight.net/api/v1';

    let headers = {};
    let body = undefined;
    if (method !== 'GET') {
        headers = {
            'Content-Type': 'application/json',
        }
        body = JSON.stringify(data);
    }

    const res = await fetch(endpoint + path, {
        method,
        headers,
        body,
    });

    if (res.status !== 200) {
        throw new Error('API request failed');
    }

    const json = await res.json();
    if (!json.ok) {
        throw new Error(json.error);
    }

    return json.data;
}

export function getAppMetadata(): Promise<AppMetadata> {
    return apiReq('/metadata');
}

export function fetchOrders(address: string): Promise<Order[]> {
    return apiReq(`/orders?address=${address}`);
}

export function fetchOrder(id: number): Promise<Order> {
    return apiReq(`/order/${id}`);
}

export function getInscribeInfo(order_id: number): Promise<InscribeInfo> {
    return apiReq(`/order/${order_id}/inscribeInfo`);
}

/**
 * @param {string} receiving_address Bitcoin address
 * @param {number} amount Amount to inscribe. Only accept integer.
 * @param {number} tx_fee Tx Fee. Only accept integer.
 * @param {boolean} freemint
 * @param {string} captcha_response reCAPTCHA challenge response
 */
export function createOrder(receiving_address: string, amount: number, tx_fee: number, freemint: boolean, captcha_response: string): Promise<Order> {
    return apiReq('/order', 'POST', {
        receiving_address,
        amount,
        tx_fee,
        freemint,
        captcha_response,
    });
}

export function checkEligible(address: string): Promise<AddressEligibility> {
    return apiReq(`/address/${address}`);
}

export function getTransactionFee(): Promise<TxFeeResponse> {
    return apiReq('/fee');
}

export type Order = {
    id: number,
    receiving_address: string,
    is_freemint: boolean,
    pay_amount: number,
    pay_address: string,
    pay_deadline: number,
    pay_txid: string | null,
    asset_amount: number,
    inscribe_fee_rate: number,
    init_txid: string | null,
    status: 'pending' | 'inscribing' | 'awaiting' | 'completed' | 'cancelled' | 'failed',
}

export type TxFeeResponse = {
    minimum: number,
    fast: number,
}

export type InscribeInfo = {
    init_tx: string;
    assets: { inscription_txid: string, inscription_id: string | null }[];
}

export type AppMetadata = {
    sale_start_ts: number,
    whitelist_protect: number,
    stock_total: number,
    stock_available: number,
    stock_inscribed: number,
    captcha_site_key: string,
    price: number,
}

export type AddressEligibility = {
    mint_start_ts: number,
    whitelist: boolean,
    freemint_quota: number,
    paidmint_quota: number
}