import Swal from 'sweetalert2';

export function copySuccess() {
    // toast top end
    return Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Copied to clipboard',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        timerProgressBar: true,
    });
}