import { createRef, useState } from 'react';
import { checkEligible } from './API';
import Swal from 'sweetalert2';
import styles from './Whitelist.module.scss';
import bg from './assets/bg2.png';
import featuredImage from './assets/5072281535200058ed9d14a2b21b456.jpg';

export function Whitelist() {
    const btcAddressRef = createRef<HTMLInputElement>();
    const [statusText, setStatusText] = useState<string>('');

    async function checkWhitelist() {
        // disable input
        // btcAddressRef.current!.disabled = true;

        try {
            const result = await checkEligible(btcAddressRef.current!.value);
            if (result.whitelist) {
                const text = `You are whitelisted!\nYou have ${result.freemint_quota} free mint(s).`;
                setStatusText(text);
                Swal.fire({
                    toast: true,
                    icon: 'success',
                    title: text,
                    showConfirmButton: false,
                    position: 'bottom-end',
                    timer: 5000,
                    timerProgressBar: true,
                })
            } else {
                const text = 'You are not whitelisted.';
                setStatusText(text);
                Swal.fire({
                    toast: true,
                    icon: 'warning',
                    title: text,
                    showConfirmButton: false,
                    position: 'bottom-end',
                    timer: 5000,
                    timerProgressBar: true,
                })
            }

        } catch (e: any) {
            console.error(e);
            const text = e?.message || 'Something went wrong. Please try again later.';
            setStatusText(text);
            Swal.fire({
                toast: true,
                icon: 'error',
                title: text,
                showConfirmButton: false,
                position: 'bottom-end',
                timer: 5000,
                timerProgressBar: true,
            })
        }

        // enable input
        // btcAddressRef.current!.disabled = false;
    }

    return <div className="bg-fit" style={{backgroundImage: `url("${bg}")`, color: 'white'}}>
        <div className="container" style={{minHeight: '100vh'}}>
            <div className="row pt-5">
                <div className="col-12 text-center">
                    <img src={featuredImage}
                         style={{maxWidth: '25rem', width: '100%', borderRadius: '1rem', imageRendering: 'pixelated'}}/>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 text-center">
                    <h1 style={{fontSize: '3.5rem'}} className="mb-4">Pixel Hares</h1>
                    <p style={{color: '#aaa'}} className="mb-5">
                        A unique pixel-art collection of 8888 hand-drawn Hares with 262 carefully curated
                        traits on a 32 x 32 artboard powered by recursive inscription technology.
                    </p>
                    <p className={styles.inputDiv}>
                        <label htmlFor="btcAddress" className="form-label mb-3" style={{fontSize: '1.4rem'}}>Your
                            Bitcoin Address</label>
                        <input type="text" className="form-control mb-3" id="btcAddress" placeholder="bc1p..."
                               ref={btcAddressRef}/>
                    </p>
                    <p>{statusText}</p>
                    <p>
                        <button className="btn btn-warning" onClick={checkWhitelist}>Check Whitelist</button>
                    </p>
                </div>
            </div>
        </div>
        <div className={styles.pageFooter}>
            <a href="https://twitter.com/OnchainHares" className={styles.footerLink}>Twitter</a>
        </div>
    </div>;
}
