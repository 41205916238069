import styles from './NotFound.module.scss';

export function NotFound() {
    return <div className={styles.notFoundPage}>
        <main>
            <div className="container-fluid h-100">
                <div className="not-found-text mt-5 text-center">
                    <h2>404 Not Found</h2>
                </div>
            </div>
        </main>
    </div>
}
