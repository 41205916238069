import styles from './Inscribe.module.scss';
import featuredImage from './assets/Untitled-1.gif';
import bg from './assets/WeChat Image_20230625170326.jpg';
import { getAddressInfo } from 'bitcoin-address-validation';
import { ChangeEvent, createRef, RefObject, useEffect, useRef, useState } from 'react';
import {
    checkEligible,
    getAppMetadata,
    createOrder as apiCreateOrder,
    getTransactionFee,
    TxFeeResponse,
    AppMetadata, AddressEligibility, Order
} from './API';
import Swal from 'sweetalert2';
import BigNumber from 'bignumber.js';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

export function Inscribe() {
    const [metadata, setMetadata] = useState<AppMetadata | null>(null);
    const [eligibility, setEligibility] = useState<AddressEligibility | null>(null);
    const [mintType, setMintType] = useState<'wl' | 'pub'>('pub');
    const [receivingAddress, setReceivingAddress] = useState<string | null>(null);
    const reCaptchaRef = createRef<ReCAPTCHA>();
    const [recommendedTxFee, setRecommendedTxFee] = useState<TxFeeResponse | null>(null);
    const txFeeInputRef = createRef<HTMLInputElement>();
    const [captchaSiteKey, setCaptchaSiteKey] = useState<string | null>(null);
    const [publicMintPrice, setPublicMintPrice] = useState<number | null>(null);
    const [stockInfo, setStockInfo] = useState<{ available: number, total: number } | null>(null);
    const navigate = useNavigate();
    const [captchaLoaded, setCaptchaLoaded] = useState(false);

    // load metadata
    useEffect(() => {
        const i = setInterval(() => {
            getAppMetadata().then(r => {
                console.log(r);
                setStockInfo({
                    available: r.stock_available,
                    total: r.stock_total
                });
            });
        }, 60000);

        getAppMetadata().then(r => {
            console.log(r);
            setMetadata(r);
            // setCaptchaSiteKey(r.captcha_site_key);
            setCaptchaSiteKey('6LdZ_8cmAAAAACzCoqrXsULeyhnJMQwlz6EBRNde');
            setPublicMintPrice(r.price);
            setStockInfo({
                available: r.stock_available,
                total: r.stock_total
            });
        });

        return () => clearInterval(i);
    }, []);

    // load tx fee every 15 seconds
    useEffect(() => {
        getTransactionFee().then(r => {
            console.log(r);
            setRecommendedTxFee(r);
        });
    }, []);

    function addrChg(e: ChangeEvent<HTMLInputElement>) {
        const addr = e.target.value;
        try {
            getAddressInfo(addr);
        } catch (e: any) {
            console.log(e!.message);
            setReceivingAddress(null);
            return;
        }

        checkEligible(addr).then((result) => {
            console.log(result);
            setEligibility(result);
            setReceivingAddress(addr);
            if (result.freemint_quota > 0) {
                setMintType('wl');
            } else {
                setMintType('pub');
            }
        }).catch(e => {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: e?.message || 'Something went wrong. Please try again later.',
                showConfirmButton: false,
                position: 'top-end',
                timer: 4000,
                timerProgressBar: true,
            })
        });
    }

    return <div className="bg-fit" style={{backgroundImage: `url("${bg}")`}}>
        {captchaSiteKey ?
            <ReCAPTCHA sitekey={captchaSiteKey} ref={reCaptchaRef} size="invisible"
                       asyncScriptOnLoad={() => {
                           setCaptchaLoaded(true);
                           console.log('captcha loaded')
                       }}/> : <></>}
        <div className="container" style={{minHeight: '100vh'}}>
            <div className={`row ${styles.mainContent}`}>
                <div className="col-12 col-lg-6">
                    <img src={featuredImage}
                         style={{
                             maxWidth: '35rem',
                             width: '100%',
                             borderRadius: '1.5rem',
                             imageRendering: 'pixelated'
                         }}/>
                </div>
                <div className={`col-12 col-lg-6 ${styles.mintInfo}`}>
                    <div className="row">
                        <div className="col">
                            <h1 className={styles.mintInfoTitle}>Pixel Hares</h1>
                        </div>
                        <div className="col-auto align-items-center d-flex">
                            <p style={{fontSize: '2rem'}} className="mb-0">
                                {!stockInfo ? '' :
                                    <span className="badge text-bg-info">
                                    Stock {stockInfo.available}/{stockInfo.total}
                                </span>
                                }
                            </p>
                        </div>

                    </div>
                    <p className={styles.mintInfoDescr}>
                        A unique pixel-art collection of 8888 hand-drawn Hares with 262 carefully curated traits on a
                        32x32 artboard powered by recursive inscription technology.
                    </p>
                    <p>
                        <label htmlFor="btcAddress" className="form-label">Your Bitcoin Address</label>
                        <input type="text" className="form-control" id="btcAddress" placeholder="bc1p..."
                               onChange={addrChg}/>
                    </p>
                    <MintTypeSelect/>
                    {mintType === 'pub' ? <PublicMintForm/> : <FreeMintForm/>}
                </div>
            </div>
            <div className={styles.socialLinks}>
                <a href="https://twitter.com/OnchainHares" target="_blank" rel="noreferrer">
                    Twitter
                </a>
                <a href="https://discord.gg/WAS4kj8Zpx" target="_blank" rel="noreferrer">
                    Discord
                </a>
            </div>
        </div>
    </div>;

    function MintTypeSelect() {
        if (!metadata) return <></>;

        if (metadata.sale_start_ts > Date.now() / 1000) {
            return <p>Sale has not started yet.</p>;
        }

        if (!eligibility) return <p>Please input your address</p>;

        if (eligibility.mint_start_ts > Date.now()) {
            return <p>You can not mint at this time.</p>;
        }

        if (!eligibility.whitelist) {
            return <></>;
        }

        return <div className="btn-group mb-3" role="group">
            <button type="button"
                    className={`btn ${mintType === 'wl' ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => setMintType('wl')}>Whitelist
            </button>
            <button type="button"
                    className={`btn ${mintType === 'pub' ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => setMintType('pub')}>Public
            </button>
        </div>;
    }

    function FreeMintForm() {
        const [amount, setAmount] = useState<number>(1);
        const inscribeBtnRef = useRef<HTMLButtonElement>(null);

        if (!eligibility) return <></>;

        if (eligibility.freemint_quota < 1) {
            return <div className="row">
                <div className="col-12">
                    <p className={styles.mintInfoPrice}>
                        You currently have no available whitelist quota.
                    </p>
                </div>
            </div>
        }

        const inputMax = eligibility.freemint_quota > 20 ? 20 : eligibility.freemint_quota;

        let amountInput = <>
            <label htmlFor="mint_amount"
                   className={`form-label ${styles.mintInfoAmount}`}>Amount: {amount}/{eligibility.freemint_quota}{eligibility.freemint_quota > 20 ? ' (Max 20 per order)' : ''}</label>
            <input type="range" className="form-range" min="1" max={inputMax} step="1"
                   defaultValue="1" id="mint_amount"
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(parseInt(e.target.value))}/>
        </>;
        if (eligibility.freemint_quota === 1) {
            amountInput = <p className={styles.mintInfoAmount}>Amount: 1 (Your Quota: 1)</p>;
        }

        let disableMintBtn = false;
        if (!eligibility || !recommendedTxFee) {
            disableMintBtn = true;
        }

        function doCreateOrder() {
            if (disableMintBtn) return;

            if (!captchaLoaded) {
                return Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Captcha not loaded. Please try again later.',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 4000,
                    timerProgressBar: true,
                });
            }

            inscribeBtnRef.current!.setAttribute('disabled', 'disabled');
            inscribeBtnRef.current!.innerText = 'Loading';

            return createOrder(amount, true).then(() => {
                inscribeBtnRef.current!.removeAttribute('disabled');
                inscribeBtnRef.current!.innerText = 'Inscribe';
            });
        }

        return <div className="row">
            <div className="col-12">
                <p className={styles.mintInfoPrice}>Mint Price: 0.00 BTC + Fee</p>
            </div>
            <div className="col-12">
                {amountInput}
            </div>
            <div className="col-12 my-3">
                <TxFeeSelector/>
            </div>
            <div className="col-12">
                <button className={'btn ' + styles.mintBtn} disabled={disableMintBtn}
                        onClick={doCreateOrder} ref={inscribeBtnRef}>Inscribe
                </button>
            </div>
        </div>
    }

    function PublicMintForm() {
        const [amount, setAmount] = useState<number>(1);
        const inscribeBtnRef = useRef<HTMLButtonElement>(null);

        if (!eligibility || !publicMintPrice) return <></>;

        const price = new BigNumber(publicMintPrice).multipliedBy(amount).div(1e8).toString();

        if (eligibility.mint_start_ts > Date.now()) {
            return <></>;
        }

        if (eligibility.paidmint_quota < 1) {
            return <div className="row">
                <div className="col-12">
                    <p className={styles.mintInfoPrice}>
                        You've reached your minting limit.
                    </p>
                </div>
            </div>
        }

        const inputMax = eligibility.paidmint_quota > 20 ? 20 : eligibility.paidmint_quota;

        let amountInput = <>
            <label htmlFor="mint_amount"
                   className={`form-label ${styles.mintInfoAmount}`}>Amount: {amount}/{eligibility.paidmint_quota}{eligibility.paidmint_quota > 20 ? ' (Max 20 per order)' : ''}</label>
            <input type="range" className="form-range" min="1" max={eligibility.paidmint_quota} step="1"
                   defaultValue="1" id="mint_amount"
                   style={{display: eligibility.paidmint_quota > 1 ? 'block' : 'none'}}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(parseInt(e.target.value))}/>
        </>;
        if (eligibility.paidmint_quota === 1) {
            amountInput = <p className={styles.mintInfoAmount}>Amount: 1 (Your Quota: 1)</p>;
        }

        let disableMintBtn = false;
        if (!eligibility || !recommendedTxFee) {
            disableMintBtn = true;
        }

        function doCreateOrder() {
            if (disableMintBtn) return;

            inscribeBtnRef.current!.setAttribute('disabled', 'disabled');
            inscribeBtnRef.current!.innerText = 'Loading';

            return createOrder(amount, false).then(() => {
                inscribeBtnRef.current!.removeAttribute('disabled');
                inscribeBtnRef.current!.innerText = 'Inscribe';
            });
        }

        return <div className="row">
            <div className="col-12">
                <p className={styles.mintInfoPrice}>Mint Price: {price} BTC + Fee</p>
            </div>
            <div className="col-12">
                {amountInput}
            </div>
            <div className="col-12 my-3">
                <TxFeeSelector/>
            </div>
            <div className="col-12">
                <button className={'btn ' + styles.mintBtn} disabled={disableMintBtn}
                        onClick={doCreateOrder} ref={inscribeBtnRef}>Inscribe
                </button>
            </div>
        </div>
    }

    function TxFeeSelector() {
        if (!recommendedTxFee) return <></>;

        return <div className="form-floating mb-3">
            <input type="number" className="form-control" placeholder={`${recommendedTxFee.fast} sats/vB`}
                   ref={txFeeInputRef} defaultValue={Math.ceil(recommendedTxFee.fast * 1.2)}/>
            <label htmlFor="txFeeInput">Fee Rate (Minimum: {recommendedTxFee.minimum + 1} sats/vB)</label>
        </div>;
    }

    async function createOrder(amount: number, is_freemint: boolean) {
        const captchaResponse = 'disabled';
        // console.log('createOrder', receivingAddress, amount, is_freemint)
        // const captchaResponse = await reCaptchaRef.current!.executeAsync();
        // console.log('captchaResponse', captchaResponse)
        // if (!captchaResponse) {
        //     Swal.fire({
        //         toast: true,
        //         icon: 'warning',
        //         title: 'Captcha incomplete',
        //         showConfirmButton: false,
        //         position: 'top-end',
        //         timer: 3000,
        //         timerProgressBar: true
        //     })
        //     return;
        // }

        let order: Order;
        try {
            order = await apiCreateOrder(receivingAddress!, amount, parseInt(txFeeInputRef.current!.value), is_freemint, captchaResponse);
        } catch (e: any) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: e.message || 'Something went wrong, please try again later.',
            })
            return;
        }

        Swal.fire({
            icon: 'success',
            title: 'Success',
            allowOutsideClick: false,
            timer: 3000,
            timerProgressBar: true
        }).then(() => {
            navigate(`/order/${order.id}`)
        })
    }
}
