import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import './App.scss';
import { NotFound } from './NotFound';
import { Inscribe } from './Inscribe';
import { Home } from './Home';
import { Whitelist } from './Whitelist';
import { OrderDetails } from './OrderDetails';
// import 'bootstrap/dist/js/bootstrap.bundle';

function App() {
    return <>
        <Router>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/whitelist" element={<Whitelist/>}/>
                <Route path="/inscribe" element={<Inscribe/>}/>
                <Route path="/order/:id" element={<OrderDetails/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    </>;
}

export default App;
